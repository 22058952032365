<template>
  <div></div>
</template>

<style scoped>
div {
  display: inline-block;
  width: 90%;
  height: 0.75px;
  background-color: black;
  padding: 0;
  margin: 0;
}
</style>